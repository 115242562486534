'use strict';

import 'date-fns';
import moment from 'moment/min/moment-with-locales';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

class CustomCalendarComponent extends React.Component {

    constructor(props) {
        super(props);
        this.dateChangeCallback = null;
        this.handleDateChange = this.handleDateChange.bind(this);

        this.state = {
            date: moment(),
            locale: 'en-gb',
            isOpen: false
        }

        moment.locale('en-gb');
    }

    updateLocale(locale) {
        this.setState({
            locale: locale,
            date: this.state.date.locale(locale)
        })
        moment.locale(locale);
    }

    open() {
        this.setState({
            isOpen: true
        })
    }

    setDate(moment) {
        this.handleDateChange(moment);
    }

    getDate() {
        return this.state.date;
    }

    handleDateChange(date) {
        this.setState({
            date: date
        });

        if (this.dateChangeCallback) {
            this.dateChangeCallback(date);
        }
    }

    setDateChangeCallback(cb) {
        this.dateChangeCallback = cb;
    }

    setIsOpen(flag) {
        this.setState({ isOpen: !!flag })
    }

    render() {
        return (
            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={this.state.locale}>
                <Grid container justify="space-around" style={{visibility: 'hidden', height: '1px', width: '1px'}}>
                    <KeyboardDatePicker
                        autoOk
                        disableToolbar
                        variant="inline"
                        // format="MM/dd/yyyy"
                        margin="normal"
                        label="Date picker inline"
                        value={this.state.date}
                        open={this.state.isOpen}
                        onOpen={() => this.setIsOpen(true)}
                        onClose={() => this.setIsOpen(false)}
                        onChange={this.handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        );
    }

}

const createDatePicker = function (elementSelector, dateChangeCallback = null) {
    // render a datepicker into the target element
    const component = ReactDOM.render(React.createElement(CustomCalendarComponent), document.querySelector(elementSelector));
    component.setDateChangeCallback(dateChangeCallback);
    return component;
}

/**
 * Allow the consuming project to set the locale future Moment instances will instantiate in.
 * Does not affect existing Moment instances.
 * @param locale
 */
const setLocale = function (locale) {
    moment.locale(locale);
}

const createMoment = function (timestamp) {
    return moment(timestamp);
}

const lib = {};

lib.setLocale = setLocale;
lib.createDatePicker = createDatePicker;
lib.createMoment = createMoment;

export default lib;
